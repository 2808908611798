import { AtomLink } from 'atoms/AtomLink'
import { Colors, StyledP } from 'commons/Theme'
import lottie from 'lottie-web'
import {
  ColumnLeft,
  ColumnRight,
  StyledPageBody,
  StyledPageGrid,
  StyledPageSubtitle,
  StyledPageTitle,
} from 'pages-style/offerta-scaduta.style'
import { useEffect, useRef } from 'react'
import offertaScadutaAnimationJSON from '../../static/assets/lottie/a2a-animation-pagina-scaduta.json'
import Layout from '../Layout'
import { removeTrailingSlashes } from '../commons/utils'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import {
  StyledAnimationWrapper,
  StyledPage,
} from '../pages-style/offerta-scaduta.style'

const ProdottoScadutoPageBody = () => {
  const animationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.body.style.backgroundColor = Colors.azure600
    const boxesAnimation = lottie.loadAnimation({
      container: animationRef.current as HTMLDivElement,
      animationData: offertaScadutaAnimationJSON,
      autoplay: false,
      loop: false,
    })
    boxesAnimation.play()
  }, [])

  return (
    <StyledPage>
      <StyledPageBody>
        <StyledPageGrid cols={1} desktopCols={2} rowsGap="80px">
          <ColumnLeft>
            <StyledPageTitle>Ops!</StyledPageTitle>
            <StyledPageSubtitle>
              Questo prodotto non è più disponibile.
            </StyledPageSubtitle>
            <StyledP $color="grey100" style={{ marginBottom: '8px' }}>
              Vuoi scoprire altri prodotti di A2A Energia?
            </StyledP>

            <AtomLink
              color="white"
              label="Vai alle offerte"
              styleType="ghost"
              to={`${removeTrailingSlashes(
                process.env.A2A_DRUPAL_CASA_URL
              )}/prodotti-casa`}
            />
            <AtomLink
              color="white"
              label="Vai alla homepage"
              styleType="ghost"
              to="/"
            />
          </ColumnLeft>
          <ColumnRight>
            <StyledAnimationWrapper ref={animationRef} />
          </ColumnRight>
        </StyledPageGrid>
      </StyledPageBody>
    </StyledPage>
  )
}

const OffertaScadutaPage = () => {
  return (
    <A2ADataContextProvider>
      <Layout noFooter pageTitle="">
        <ProdottoScadutoPageBody />
      </Layout>
    </A2ADataContextProvider>
  )
}

export default OffertaScadutaPage
